<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list" border>
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="icon" label="图标">
          <template #default="s">
            <img class="flex-def" :src="s.row.icon | tomedia" style="height: 2rem;width: 2rem;border-radius: .2rem" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="link" label="跳转链接"></el-table-column>
        <el-table-column label="操作" align="center">
          <template #header>
            <el-button @click="editFormBox=true" type="primary">添加宫格导航</el-button>
          </template>
          <template #default="s">
            <el-button @click="editItem(s.row)" type="primary">编辑</el-button>
            <el-button @click="del(s.row)" type="danger">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="编辑宫格导航" :visible.sync="editFormBox" @close="editFormClose">
        <el-form label-width="3rem" style="padding: 0 2rem">
          <el-form-item label="排序">
            <el-input-number v-model="form.sort"></el-input-number>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="图标">
            <y_upload_img v-model="form.icon"></y_upload_img>
          </el-form-item>
          <el-form-item label="链接">
            <y_choose_link v-model="form.link"></y_choose_link>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editFormBox = false">取 消</el-button>
          <el-button type="primary" @click="edit">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import y_upload_img from "@/components/y_upload/y_upload_img";
import y_choose_link from "@/components/y_choose_link";
export default {
  name: "gird",
  components:{
    y_upload_img,
    y_choose_link
  },
  data() {
    return {
      list:[],
      form:{
        sort:0,
        title:"",
        icon:"",
        link:""
      },
      editFormBox:false,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    del(item){
      this.$api.system.page.girdDel({id:item.id}).then(()=>{
        this.$message.success("操作成功");
        this.editFormBox = false;
        this.load();
      })
    },
    editItem(item){
      this.form = {...item};
      this.editFormBox = true;
    },
    editFormClose(){
      this.form = {
        sort:0,
        title:"",
        icon:"",
        link:""
      }
      this.editFormBox = false;
    },
    load() {
      this.$api.system.page.girdAll().then(res=>{
        this.list = res;
      })
    },
    edit(){
      this.$api.system.page.girdEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.editFormBox = false;
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>